@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: 'Garamond';
  src: url('./Garamond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond Bold';
  src: url('./Garamond_bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Medium';
  src: url('./Futura_medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Bold';
  src: url('./Futura_bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*.homeBack {
  background: linear-gradient(112.87deg, #fff3f6 0%, #ffffff 100%);
}
*/


* {
  font-family: "Futura Medium";
  letter-spacing: 1px;
}


@keyframes dark-fade {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.homeBack{
  background: linear-gradient(112deg,#fff3f6, #FFE1E9,   #fff 100%);
  background-size: 200% 200%;    animation: dark-fade 20s linear infinite;
  display: flex;
  align-items: center;
}

input {
  border-radius: 0px;
  text-align: left;
}


#back, #next {
  border: 1px solid rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  width: 117px;
  height: 43px;
}

#back {
background: none;
}

#next {
  background: rgba(255, 255, 255, 0.60);
}

#logo {
  max-width: 320px;
}


.MuiOutlinedInput-notchedOutline {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0px !important;
  border-bottom: solid 2px;
  border-width: 2px !important;
  border-color: rgba(255, 255, 255, 0.60);
  color: rgba(255, 255, 255, 0.60) !important;

}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
  border-width: 2px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.6rem !important;
  font-family: "Futura medium" !important;
}


.MuiSvgIcon-root {
  margin-top: .5rem !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.6rem !important;
  font-family: "Futura medium" !important;
}

input.bottom-placeholder {
  padding-bottom: 8px; /* Adjust this value to increase/decrease space between placeholder and bottom of input box */

}

textarea.bottom-placeholder::-webkit-input-placeholder {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important; /* This aligns the placeholder to the left side of the textarea */
}

.select-placeholder {

 font-size: 3rem !important;
color: #FFE1E9 !important;
}
.logoHolder{
  width:80vw;
  margin-left: auto ; 
  margin-right: auto; 
  display: flex;
  justify-content: space-around;
}
.form-hold{
  width:90vw;
  max-width: 100%; /* Add this line */
  margin-left: auto ; /* Add this line */
  margin-right: auto; /* Add this line */
}


.notice-font {
  font-size: 12px; /* Adjust this value according to your needs */
}

.notice-star {
  font-weight: 1800;
}

